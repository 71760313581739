<template>
  <main id="main" class="site-main mt8">
    <article>
      <h1 class="entry-title">Lisa Hülsdau</h1>
      <PortableText :content="bio" />
    </article>
  </main>
</template>

<script>
import { ref, onMounted } from "vue";
import PortableText from "../components/PortableText.vue";
import useSanity from "../use/useSanity";

export default {
  components: { PortableText },
  setup() {
    const { getBio } = useSanity();
    const bio = ref([]);

    onMounted(async () => {
      bio.value = await getBio();
    });

    return { bio };
  },
};
</script>
<style lang="scss" scoped>
#lisa {
  float: none;
}

@media only screen and (min-width: 450px) and (max-width: 993px) {
  #lisa {
    margin: 0 1.5rem 0.5rem 0;
  }
}

@media only screen and (min-width: 450px) {
  #lisa {
    float: left;
    margin: 0 1.5rem 0.5rem 0;
    max-width: 13.5rem;
  }
}
</style>
